<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" :rules="rules.country">
        <euro-select v-model="country" :label="$t('Country') + '*'" :error-messages="errors"
          :options="countriesSelectable" searchable></euro-select>
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" vid="from_date" :name="$t('From date')" :rules="rules.from_date">
        <date-picker v-model="form.from_date" :overrides="fromDateOverrides" :error-messages="errors"
          :label="$t('From date') + '*'">
        </date-picker> </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('To date')" vid="to_date" :rules="rules.to_date">
        <date-picker v-model="form.to_date" :overrides="toDateOverrides" :error-messages="errors"
          :label="$t('To date') + '*'">
        </date-picker>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Description')" vid="description" :rules="rules.description">
        <euro-text-area v-model="form.description" :label="$t('Description') + '*'"
          :error-messages="errors"></euro-text-area>
      </validation-provider>
    </div>
    <div class="col-12">
      <div class="form-group">
        <div class="checkbox-list">
          <label class="checkbox">
            <input v-model="form.subcontracting_allowed" type="checkbox" name="Checkboxes2" />
            <span></span>
            {{ $t('Clause 1: Subcontracting Allowed') }}
          </label>
          <label class="checkbox">
            <input v-model="form.portal_use" type="checkbox" name="Checkboxes2" />
            <span></span>
            {{ $t('Clause 2: Can Use Portal') }}
          </label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" required class="w-100">
        <euro-tus-file-input :label="$t('Attachment')" :error-messages="errors" accept="application/pdf"
          :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment"
          @change="onAttachmentChange"></euro-tus-file-input>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import datePicker from "@/view/components/datePicker/datePicker.vue";
const now = new Date();

export default {
  components: {
    datePicker
  },
  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    defaultCountry: {
      type: String,
      default: "",
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxDate: now,
      country: "",
      form: {
        counterpart: "",
        description: "",
        from_date: null,
        to_date: null,
        subcontracting_allowed: false,
        portal_use: false,
        attachment_tus: null,
        // TODO: company:
      },
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),

    rules() {
      return {
        country: {
          required: true,
        },
        description: {
          required: true
        },

        attachment: {},
      };
    },

    stringMaxDate() {
      return moment(this.maxDate, true).format("YYYY-MM-DD");
    },

    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    toDateOverrides() {
      return this.form.from_date ? { minDate: moment(this.form.from_date) } : null;
    },
    fromDateOverrides() {
      return this.form.from_date ? { maxDate: moment() } : null;
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },

  mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
  },

  methods: {
    onAttachmentChange(e) {
      this.form.attachment_tus = e;
    },
  },
};
</script>
