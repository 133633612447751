var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": _vm.rules.country
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "label": _vm.$t('Country') + '*',
            "error-messages": errors,
            "options": _vm.countriesSelectable,
            "searchable": ""
          },
          model: {
            value: _vm.country,
            callback: function callback($$v) {
              _vm.country = $$v;
            },
            expression: "country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "from_date",
      "name": _vm.$t('From date'),
      "rules": _vm.rules.from_date
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.fromDateOverrides,
            "error-messages": errors,
            "label": _vm.$t('From date') + '*'
          },
          model: {
            value: _vm.form.from_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "from_date", $$v);
            },
            expression: "form.from_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('To date'),
      "vid": "to_date",
      "rules": _vm.rules.to_date
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.toDateOverrides,
            "error-messages": errors,
            "label": _vm.$t('To date') + '*'
          },
          model: {
            value: _vm.form.to_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "to_date", $$v);
            },
            expression: "form.to_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Description'),
      "vid": "description",
      "rules": _vm.rules.description
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-text-area', {
          attrs: {
            "label": _vm.$t('Description') + '*',
            "error-messages": errors
          },
          model: {
            value: _vm.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "description", $$v);
            },
            expression: "form.description"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "checkbox-list"
  }, [_c('label', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.subcontracting_allowed,
      expression: "form.subcontracting_allowed"
    }],
    attrs: {
      "type": "checkbox",
      "name": "Checkboxes2"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.subcontracting_allowed) ? _vm._i(_vm.form.subcontracting_allowed, null) > -1 : _vm.form.subcontracting_allowed
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.subcontracting_allowed,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "subcontracting_allowed", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "subcontracting_allowed", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "subcontracting_allowed", $$c);
        }
      }
    }
  }), _c('span'), _vm._v(" " + _vm._s(_vm.$t('Clause 1: Subcontracting Allowed')) + " ")]), _c('label', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.portal_use,
      expression: "form.portal_use"
    }],
    attrs: {
      "type": "checkbox",
      "name": "Checkboxes2"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.portal_use) ? _vm._i(_vm.form.portal_use, null) > -1 : _vm.form.portal_use
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.portal_use,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "portal_use", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "portal_use", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "portal_use", $$c);
        }
      }
    }
  }), _c('span'), _vm._v(" " + _vm._s(_vm.$t('Clause 2: Can Use Portal')) + " ")])])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.$t('Attachment'),
            "error-messages": errors,
            "accept": "application/pdf",
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": _vm.onAttachmentChange
          }
        })];
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }