<template>
  <div>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">
            {{ $t("Documents") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("Update your documents") }}</span>
        </div>
        <div class="card-toolbar">
          <euro-select v-model="documentType" class="min-w-200px mr-4 mb-0"
            :multiselect-props="multiselectProps"></euro-select>
          <b-dropdown no-caret right no-flip text="Document" variant="light-primary"
            toggle-class="font-weight-bolder py-3">
            <template #button-content>
              <div class="dropdown-button">
                <span class="svg-icon menu-icon">
                  <inline-svg src="/media/svg/icons/Files/File.svg" />
                </span>
                <span class="">{{ $t("Add document") }}</span>
              </div>
            </template>
            <div class="navi navi-hover min-w-md-250px">
              <b-dropdown-text class="navi-item" tag="div">
                <button class="create-button navi-link" @click="openModal('create-vat-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    VAT
                  </span>
                </button>
                <button class="create-button navi-link" @click="openModal('legal-person-create-fiscal-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Fiscal Code") }}
                  </span>
                </button>
                <button class="create-button navi-link" @click="openModal('create-registration-certificate-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("National CCIAA Certificate") }}
                  </span>
                </button>
                <button class="create-button navi-link" @click="openModal('create-ins-third-party-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Insurance - Third Party") }}
                  </span>
                </button>
                <button class="create-button navi-link" @click="openModal('create-social-security-modal')">
                  <span class="navi-icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="navi-text">
                    {{ $t("Social Security Office Registration") }}
                  </span>
                </button>
              </b-dropdown-text>
            </div>
          </b-dropdown>
        </div>
      </div>
      <div class="card-body detail">
        <datatable ref="tableRef" :table-props="tableProps" :total="total" :options.sync="tableOptions"
          :per-page-options="perPageOptions">
          <template #[`cell.document_type`]="{ item }">
            <span :class="`font-weight-bold label label-inline label-light-${docTypes[item.document_type].labelColor}`">
              {{ docTypes[item.document_type].typeName }}
            </span>
          </template>
          <template #[`cell.name`]="{ item }">
            <template v-if="item.label !== '' || item.is_authorized">
                {{ docTypes[item.document_type].name(item) }}
              </template>
              <template v-else>
                <b-tooltip :target="'unauth-label-' + item.id">
                  {{ $t('Unauthorized to see this data') }}
                </b-tooltip>
                <label :id="'unauth-label-' + item.id">*****</label>
              </template>

            <template v-if="item.document_type == 'CompanyRegistrationCertificate'">
              <div class="text-muted text-size-sm">
                {{ $t("Address") + ": " + item.data.address }}
              </div>
            </template>

            <template v-if="item.document_type == 'CompanySocialSecurityCertificate'">
              <div class="text-muted text-size-sm">
                {{ $t("Country") + ": " + item.country }}
              </div>
            </template>
            
            <template v-if="item.document_type == 'DeclarationOfDetachment'">
              <div class="text-muted text-size-sm">
                {{ $t("Declaration date") + ": " + item.data.declaration_date }}
              </div>
            </template>

            <template v-if="item.document_type == 'employee_set'">
              <div class="text-muted text-size-sm">
                {{ $t("Country") + ": " + item.persona.stato }}
              </div>
              <div class="text-muted text-size-sm">
                {{ $t("Date of birth") + ": " + formattedDate(item.persona.date_birth) }}
              </div>
            </template>
          </template>

          <template #[`cell.from_date`]="{ item }">
            <span class="font-weight-bolder text-muted">{{ formattedDate(item.from_date) }}</span>
          </template>
          <template #[`cell.to_date`]="{ item }">
            <span class="font-weight-bolder text-muted">{{ formattedDate(item.to_date) }}</span>
          </template>
          <template #[`cell.actions`]="{ item }">
            <div class="d-flex">
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                :title="$t('Download the documents')" :class="{ disabled: !item.attachment }" :disabled="!item.attachment"
                @click="download(item)">
                <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': item.attachment }">
                  <inline-svg src="/media/svg/icons/General/Clip.svg" />
                </span>
              </b-button>
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                :class="{ disabled: !docTypes[item.document_type]?.isEditable(item) }" :title="item.is_authorized ? $t('Edit') : $t('You are not authorized')"
                :disabled="!docTypes[item.document_type]?.isEditable(item)"
                @click="onEditBtnClick(item, docTypes[item.document_type]?.modal)">
                <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': docTypes[item.document_type]?.isEditable(item) }">
                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                </span>
              </b-button>
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm"
                :class="{ disabled: !docTypes[item.document_type]?.isDeletable(item) }" :title="item.is_authorized ? $t('Delete') : $t('You are not authorized')"
                :disabled="!docTypes[item.document_type]?.isDeletable(item)" @click="onDeleteBtnClick(item)">
                <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': docTypes[item.document_type]?.isDeletable(item) }">
                  <inline-svg src="/media/svg/icons/General/Trash.svg" />
                </span>
              </b-button>
            </div>
          </template>
        </datatable>
      </div>
    </div>

    <LegalPersonCreateVatModal :existing-form="editForm" :legal-person="legalPerson" :countries="countries"
      :saving="saving" @refresh="refreshTable">
    </LegalPersonCreateVatModal>
    <LegalPersonCreateFiscalModal :existing-form="editForm" :legal-person="legalPerson" :countries="countries"
      :saving="saving" @refresh="refreshTable"></LegalPersonCreateFiscalModal>
    <LegalPersonCreateProcContractModal :existing-form="editForm" :legal-person="legalPerson" :countries="countries"
      @refresh="refreshTable">
    </LegalPersonCreateProcContractModal>
    <LegalPersonCreateRegistrationCertificateModal :existing-form="editForm" :legal-person="legalPerson"
      :countries="countries" @refresh="refreshTable"></LegalPersonCreateRegistrationCertificateModal>
    <LegalPersonCreateInsThirdPartyModal :existing-form="editForm" :legal-person="legalPerson" :countries="countries"
      @refresh="refreshTable">
    </LegalPersonCreateInsThirdPartyModal>
    <LegalPersonCreateSocialSecurityModal :existing-form="editForm" :legal-person="legalPerson" :countries="countries"
      @refresh="refreshTable">
    </LegalPersonCreateSocialSecurityModal>
    <!--
    <LegalPersonCreateVehicleModal :existing-form="editForm" :legal-person="legalPerson" :countries="countries"
      @refresh="refreshTable">
    </LegalPersonCreateVehicleModal>
  -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// Service
import DateService from "@/core/services/date.service";
import CompanyService from "@/core/services/company/company.service";
import CompanyVatService from "@/core/services/company/company-vat.service";
import CompanyFiscalService from "@/core/services/company/company-fiscal.service";
import CompanyInsuranceCertificateService from "@/core/services/company/company-insurancecertificate.service";
import CompanyRegistrationCertificateService from "@/core/services/company/company-registration-certificate.service";
import CompanySocialSecurity from "@/core/services/company/company-socialsecurity.service";
import CompanyVehicleService from "@/core/services/company/company-vehicle.service";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

// Modal
import LegalPersonCreateVatModal from "@/view/components/modals/legal-person/LegalPersonCreateVatModal.vue";
import LegalPersonCreateFiscalModal from "@/view/components/modals/legal-person/LegalPersonCreateFiscalModal.vue";
import LegalPersonCreateProcContractModal from "@/view/components/modals/legal-person/LegalPersonCreateProcContractModal.vue";
import LegalPersonCreateRegistrationCertificateModal from "@/view/components/modals/legal-person/LegalPersonCreateRegistrationCertificateModal.vue";
import LegalPersonCreateInsThirdPartyModal from "@/view/components/modals/legal-person/LegalPersonCreateInsThirdPartyModal.vue";
import LegalPersonCreateSocialSecurityModal from "@/view/components/modals/legal-person/LegalPersonCreateSocialSecurityModal.vue";
// Other
import { mapState, mapGetters } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import moment from "moment";
const today = new Date();
import Swal from "sweetalert2";
import PhysicalPersonPaycheckService from "@/core/services/physical-person/physical-person-employee-paycheck.service";

export default {
  components: {
    LegalPersonCreateVatModal,
    LegalPersonCreateFiscalModal,
    LegalPersonCreateProcContractModal,
    LegalPersonCreateRegistrationCertificateModal,
    LegalPersonCreateInsThirdPartyModal,
    LegalPersonCreateSocialSecurityModal,
    //LegalPersonCreateVehicleModal
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editForm: {},
      documentType: null,
      saving: false,
      apiErrors: null,
      registerCertificates: [],
      documentOptions: [],
      // items: [],
      fields: [
        {
          label: this.$t("Type"),
          key: "document_type",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("Name"),
          key: "name",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("From Date"),
          key: "date_start",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("To Date"),
          key: "date_end",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("Actions"),
          key: "actions",
          class: "align-end col-actions",
        },
      ],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
    };
  },

  computed: {
    ...mapState("constants", ["countries", "languages"]),
    ...mapGetters("user", ["dateFormat"]),
    

    multiselectProps() {
      return {
        options: this.documentOptions,
        searchable: false,
        showLabels: false,
        "allow-empty": false,
      };
    },

    docTypes() {
      return {
        ExtraCompanyVAT: {
          typeName: "VAT",
          labelColor: "warning",
          name: item => item.label,
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-vat-modal",
          downLoadFunc: item => CompanyVatService.getOne(item.id),
          delFunc: item => CompanyVatService.delete(item.id),
        },
        CompanyFiscalID: {
          typeName: "Fiscal ID",
          labelColor: "danger",
          name: item => item.country + " " + item.label,
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "legal-person-create-fiscal-modal",
          downLoadFunc: item => CompanyFiscalService.getOne(item.id),
          delFunc: item => CompanyFiscalService.delete(item.id),
        },
        CompanyRegistrationCertificate: {
          typeName: "Registration Certificate",
          labelColor: "primary",
          name: item => (item.is_authorized ? item.label : 'xxxxxx'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-registration-certificate-modal",
          downLoadFunc: item => CompanyRegistrationCertificateService.getOne(item.id), // Bug
          delFunc: item => CompanyRegistrationCertificateService.delete(item.id), // BUG
        },
        InsuranceCertificate: {
          typeName: "Insurance Certificate",
          labelColor: "danger",
          name: item => (item.is_authorized ? item.label : 'xxxxxx'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-ins-third-party-modal",
          downLoadFunc: item => CompanyInsuranceCertificateService.getOne(item.id),
          delFunc: item => CompanyInsuranceCertificateService.delete(item.id),
        },
        CompanySocialSecurityCertificate: {
          typeName: "Social Security",
          labelColor: "success",
          name: item => (item.is_authorized ? item.label : 'xxxxxx'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-social-security-modal",
          downLoadFunc: item => CompanySocialSecurity.getOne(item.id),
          delFunc: item => CompanySocialSecurity.delete(item.id),
        },

        CompanyVeichles: {
          typeName: "Company Vehicles",
          labelColor: "warning",
          name: item => (item.is_authorized ? item.label : 'xxxxxx'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "create-vehicle-modal",
          downLoadFunc: item => CompanyVehicleService.getOne(item),
          delFunc: item => CompanyVehicleService.delete(item),
        },
        // Forse in futuro
        CompanyProcurementContract: {
          typeName: "Procurement Contract",
          labelColor: "danger",
          name: item => (item.is_authorized ? item.label : 'xxxxxx'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "TO-ADD",
          downLoadFunc: item => alert("TODO: " + item),
          delFunc: item => alert("TODO: " + item),
        },
        social_security_insurance: {
          typeName: "Social Security Insurance",
          labelColor: "danger",
          name: item => (item.is_authorized ? item.label : 'xxxxxx'),
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "TO-ADD",
          downLoadFunc: item => alert("TODO: " + item),
          delFunc: item => alert("TODO: " + item),
        },
        RegistrationReport: {
          typeName: "Company Registration Report",
          labelColor: "warning",
          name: item => item.country,
          isEditable: item => item.is_authorized,
          isDeletable: item => item.is_authorized,
          modal: "TO-ADD",
          downLoadFunc: item => alert("TODO: " + item),
          delFunc: item => alert("TODO: " + item),
        },
        DeclarationOfDetachment: {
          typeName: "Declaration of detachment",
          labelColor: "warning",
          name: item => (item.is_authorized ? item.label : 'xxxxxx'),
          isEditable: () => false,
          isDeletable: item => item.is_authorized,
          modal: "TO-ADD",
          downLoadFunc: item => window.open(item.attachment, "_blank"),
          delFunc: item => alert("TODO: " + item),
        },
      };
    },

    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.documentType,
        "no-sort-reset": true,
        responsive: true,
        "tbody-tr-class": this.trClass,
      };
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Documents") },
    ]);
  },

  methods: {
    refreshTable() {
      this.$emit("refresh");
      this.$refs.tableRef.refresh();
    },

    itemProvider(ctx, callback) {
      CompanyService.getDocuments({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
        companyId: this.legalPerson.id,
        fields: ctx.filter,
      }).then((res) => {
          let out = res.results;
          if (this.documentOptions.length === 0) {
            this.documentOptions = res.document_types.map((key) => {
              return {
                value: key,
                text: this.docTypes[key].typeName,
              };
            });
          }
          try {
            out.map((item) => {
              if (!this.docTypes[item.document_type]) {
                console.error(
                  `docTypes does not have a property ${item.document_type}`
                );
              } else if (
                typeof this.docTypes[item.document_type].name !== "function"
              ) {
                console.error(
                  `docTypes[${item.document_type}] does not have a method named 'name'`
                );
              } else {
                const name = this.docTypes[item.document_type].name(item);
                if (typeof name !== "string") {
                  console.error(
                    `docTypes[${item.document_type}].name does not return a string value`
                  );
                }
              }
            });
          } catch (error) {
            console.error(error);
          }
          this.total = res.count;
          this.loading = false;
          callback(out);
        })
        .catch((err) => {
          this.loading = false;
          console.error(err)
          callback([]);
        });
    },

    formattedDate(date) {
      if (!date) {
        return "";
      }

      return DateService.format(date, this.dateFormat);
    },
    

    openModal(id) {
      this.editForm = {};
      this.$bvModal.show(id);
    },

    async onEditBtnClick(item, modalId) {
      let downLoadFunc;
      downLoadFunc = this.docTypes[item.document_type].downLoadFunc;
      try {
        const res = await downLoadFunc(item);
        this.editForm = { ...res };
        this.$bvModal.show(modalId);
      } catch (err) {
        let message = "";
        if (err?.response?.status === 400) {
          message = err.response.data;
        } else {
          message = err?.response?.data?.detail ?? this.$t("Something went wrong!");
        }
        await backendErrorSwal(message, err);
      }
    },

    async download(item) {
      const downLoadFunc = this.docTypes[item.document_type].downLoadFunc;
      try {
        const res = await downLoadFunc(item);

        window.open(res.attachment, "_blank");
      } catch (err) {
        let message = "";
        if (err?.response?.status === 400) {
          message = err.response.data;
        } else {
          message = err?.response?.data?.detail ?? this.$t("Something went wrong!");
        }
        backendErrorSwal(err, message);
      }
    },

    async onDeleteBtnClick(item) {
      await Swal.fire({
        title: this.$t("This document will be deleted, are you sure?"),
        icon: "warning",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.docTypes[item.document_type].delFunc(item);
            Swal.fire(this.$t("Success"), this.$t("Document deleted"), "success");
            await this.refreshTable()
          } catch (err) {
            console.log(err);
            let message = "";
            if (err?.response?.status === 400) {
              message = err.response.data;
            } else {
              message = err?.response?.data?.detail ?? this.$t("Unable to delete the document");
            }
            backendErrorSwal(err, message);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.create-button {
  text-align: left;
  width: 100%;
}

.create-button::v-deep button {
  width: 100%;
  text-align: left;
}

::v-deep .dropdown-menu {
  max-height: 325px;
  overflow-y: auto;
}

::v-deep .dropdown-item {
  padding: 5px;
}

::v-deep .btn.btn-outline-light {
  color: #fff;
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light span {
  color: white;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:hover i::before,
.btn.btn-outline-light:active span,
.btn.btn-outline-light.dropdown-toggle[aria-expanded="true"] span,
.btn.btn-outline-light.dropdown-toggle[aria-expanded="true"] i::before,
.btn.btn-outline-light:active i::before {
  color: var(--primary);
}
</style>
