<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="create-proc-contract-modal" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Edit Procurement Contract") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create procurement contracts") }}</h4>
          </template>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">{{ $t('Cancel') }}</b-button>
          </div>
        </div>
      </template>

      <legal-person-procurement-contract-form :countries="countries" :existing-form="existingForm"
        @input="onLegalPersonProcurementContractFormInput"></legal-person-procurement-contract-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import LegalPersonProcurementContractForm from "@/view/components/forms/legal-person/LegalPersonProcurementContractForm.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import Swal from "sweetalert2";
import CompanyProcurementService from "@/core/services/company/company-procurement.service";

export default {
  components: {
    LegalPersonProcurementContractForm,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    existingForm: {
      type: Object,
      default: () => true,
    }
  },

  data() {
    return {
      form: null,
      isSaving: false,
      apiErrors: null,
    };
  },

  computed: {
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    }
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {};
        for (const key in this.form) {
          newErrors[key] = [...(val[key] ?? [])];
        }
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("create-proc-contract-modal");
    },

    onModalHidden() {
      this.form = null;
    },

    async submit() {
      this.isSaving = true;
      try {
        if (this.isEdit) {
          const form = { ...this.item }
          await CompanyProcurementService.update(form, form.id)
        } else {
          await CompanyProcurementService.create({ ...this.form, company: this.legalPerson.id });
        }
        this.$emit("refresh");
        this.closeModal();

        let title = this.$t("Document created")
        if (this.isEdit) {
          title = this.$t("Document Updated")
        }
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the document. Please try again.")
          if (this.isEdit) {
            message = err?.response?.data?.detail ?? this.$t("Failed to update the document. Please try again.")
          }
          backendErrorSwal(message, err);
        }
      } finally {
        this.isSaving = false;
      }
    },

    onLegalPersonProcurementContractFormInput(form) {
      this.form = form;
    },
  },
};
</script>
