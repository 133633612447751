var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Documents")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t("Update your documents")))])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-select', {
    staticClass: "min-w-200px mr-4 mb-0",
    attrs: {
      "multiselect-props": _vm.multiselectProps
    },
    model: {
      value: _vm.documentType,
      callback: function callback($$v) {
        _vm.documentType = $$v;
      },
      expression: "documentType"
    }
  }), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "right": "",
      "no-flip": "",
      "text": "Document",
      "variant": "light-primary",
      "toggle-class": "font-weight-bolder py-3"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "dropdown-button"
        }, [_c('span', {
          staticClass: "svg-icon menu-icon"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Files/File.svg"
          }
        })], 1), _c('span', {}, [_vm._v(_vm._s(_vm.$t("Add document")))])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "navi navi-hover min-w-md-250px"
  }, [_c('b-dropdown-text', {
    staticClass: "navi-item",
    attrs: {
      "tag": "div"
    }
  }, [_c('button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('create-vat-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" VAT ")])]), _c('button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('legal-person-create-fiscal-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Fiscal Code")) + " ")])]), _c('button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('create-registration-certificate-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("National CCIAA Certificate")) + " ")])]), _c('button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('create-ins-third-party-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Insurance - Third Party")) + " ")])]), _c('button', {
    staticClass: "create-button navi-link",
    on: {
      "click": function click($event) {
        return _vm.openModal('create-social-security-modal');
      }
    }
  }, [_c('span', {
    staticClass: "navi-icon"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('span', {
    staticClass: "navi-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Social Security Office Registration")) + " ")])])])], 1)])], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('datatable', {
    ref: "tableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.document_type",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          class: "font-weight-bold label label-inline label-light-".concat(_vm.docTypes[item.document_type].labelColor)
        }, [_vm._v(" " + _vm._s(_vm.docTypes[item.document_type].typeName) + " ")])];
      }
    }, {
      key: "cell.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.label !== '' || item.is_authorized ? [_vm._v(" " + _vm._s(_vm.docTypes[item.document_type].name(item)) + " ")] : [_c('b-tooltip', {
          attrs: {
            "target": 'unauth-label-' + item.id
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Unauthorized to see this data')) + " ")]), _c('label', {
          attrs: {
            "id": 'unauth-label-' + item.id
          }
        }, [_vm._v("*****")])], item.document_type == 'CompanyRegistrationCertificate' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Address") + ": " + item.data.address) + " ")])] : _vm._e(), item.document_type == 'CompanySocialSecurityCertificate' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Country") + ": " + item.country) + " ")])] : _vm._e(), item.document_type == 'DeclarationOfDetachment' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Declaration date") + ": " + item.data.declaration_date) + " ")])] : _vm._e(), item.document_type == 'employee_set' ? [_c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Country") + ": " + item.persona.stato) + " ")]), _c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Date of birth") + ": " + _vm.formattedDate(item.persona.date_birth)) + " ")])] : _vm._e()];
      }
    }, {
      key: "cell.from_date",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.formattedDate(item.from_date)))])];
      }
    }, {
      key: "cell.to_date",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.formattedDate(item.to_date)))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref5) {
        var _vm$docTypes$item$doc, _vm$docTypes$item$doc2, _vm$docTypes$item$doc4, _vm$docTypes$item$doc5, _vm$docTypes$item$doc6, _vm$docTypes$item$doc7;
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          class: {
            disabled: !item.attachment
          },
          attrs: {
            "title": _vm.$t('Download the documents'),
            "disabled": !item.attachment
          },
          on: {
            "click": function click($event) {
              return _vm.download(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': item.attachment
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Clip.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          class: {
            disabled: !((_vm$docTypes$item$doc = _vm.docTypes[item.document_type]) !== null && _vm$docTypes$item$doc !== void 0 && _vm$docTypes$item$doc.isEditable(item))
          },
          attrs: {
            "title": item.is_authorized ? _vm.$t('Edit') : _vm.$t('You are not authorized'),
            "disabled": !((_vm$docTypes$item$doc2 = _vm.docTypes[item.document_type]) !== null && _vm$docTypes$item$doc2 !== void 0 && _vm$docTypes$item$doc2.isEditable(item))
          },
          on: {
            "click": function click($event) {
              var _vm$docTypes$item$doc3;
              return _vm.onEditBtnClick(item, (_vm$docTypes$item$doc3 = _vm.docTypes[item.document_type]) === null || _vm$docTypes$item$doc3 === void 0 ? void 0 : _vm$docTypes$item$doc3.modal);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': (_vm$docTypes$item$doc4 = _vm.docTypes[item.document_type]) === null || _vm$docTypes$item$doc4 === void 0 ? void 0 : _vm$docTypes$item$doc4.isEditable(item)
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          class: {
            disabled: !((_vm$docTypes$item$doc5 = _vm.docTypes[item.document_type]) !== null && _vm$docTypes$item$doc5 !== void 0 && _vm$docTypes$item$doc5.isDeletable(item))
          },
          attrs: {
            "title": item.is_authorized ? _vm.$t('Delete') : _vm.$t('You are not authorized'),
            "disabled": !((_vm$docTypes$item$doc6 = _vm.docTypes[item.document_type]) !== null && _vm$docTypes$item$doc6 !== void 0 && _vm$docTypes$item$doc6.isDeletable(item))
          },
          on: {
            "click": function click($event) {
              return _vm.onDeleteBtnClick(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': (_vm$docTypes$item$doc7 = _vm.docTypes[item.document_type]) === null || _vm$docTypes$item$doc7 === void 0 ? void 0 : _vm$docTypes$item$doc7.isDeletable(item)
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  })], 1)]), _c('LegalPersonCreateVatModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.legalPerson,
      "countries": _vm.countries,
      "saving": _vm.saving
    },
    on: {
      "refresh": _vm.refreshTable
    }
  }), _c('LegalPersonCreateFiscalModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.legalPerson,
      "countries": _vm.countries,
      "saving": _vm.saving
    },
    on: {
      "refresh": _vm.refreshTable
    }
  }), _c('LegalPersonCreateProcContractModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.legalPerson,
      "countries": _vm.countries
    },
    on: {
      "refresh": _vm.refreshTable
    }
  }), _c('LegalPersonCreateRegistrationCertificateModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.legalPerson,
      "countries": _vm.countries
    },
    on: {
      "refresh": _vm.refreshTable
    }
  }), _c('LegalPersonCreateInsThirdPartyModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.legalPerson,
      "countries": _vm.countries
    },
    on: {
      "refresh": _vm.refreshTable
    }
  }), _c('LegalPersonCreateSocialSecurityModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "legal-person": _vm.legalPerson,
      "countries": _vm.countries
    },
    on: {
      "refresh": _vm.refreshTable
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }